import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsx mdx */

import DefaultLayout from "/opt/build/repo/src/components/posts-layout.tsx";
export const _frontmatter = {};

const makeShortcode = name => function MDXDefaultShortcode(props) {
  console.warn("Component " + name + " was not imported, exported, or provided by MDXProvider as global scope");
  return <div {...props} />;
};

const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">


    <p><em parentName="p">{`Thème du défi : La danse`}</em></p>
    <p>{`Le mouvement était devenu machinal. Gauche. Droite. Gauche. Droite. Il n’y avait plus à réfléchir, la productivité de l’Empire était ce qui comptait le plus et dans la rue, il n’y avait qu’à rejoindre son poste de travail. On marche et on ne s’arrête pas. Gauche. Droite. Gauche. Droite. La marche était rythmée et contrôlée. la garde impériale faisait en sorte que le pas soit coordonné et la cadence soutenue. Personne ne pouvait s’arrêter pour ne pas causer de perte de productivité. Depuis ces quelques mois désormais que le coup d’état s’était déroulé, l’Empire avait mis en place une surveillance sans faille.`}</p>
    <p>{`Ce que la garde n’avait pas remarqué, c’est que Luis avait changé d’itinéraire aujourd’hui. Il savait que ce n’était pas permis mais arpenter le même chemin tous les jours le rendait fou. Il fit un détour ce jour-là en espérant que personne ne le remarque. Gauche. Droite. Gauche. Droite. Le mouvement était le même mais au moins il pouvait voir un environnement différent. Il se surprit lui même à sourire en voyant les devantures de boutiques fermées qu’il n’avait pas revu depuis le coup d’état. A la suite de la prise de pouvoir de l’Empereur Nelson, les déplacements étaient strictement limités au trajet vers le lieu de travail. La nourriture devait être livrée et les moyens de communication limités, le commandement impérial savait qu’il devait restreindre les rassemblements. Gauche. Droite. Gauche. Droite. Il marchait sur ce même rythme incessant qu’il tenait tous les jours lorsque ses yeux se posèrent sur la façade du Rio Bar.`}</p>
    <p>{`Ses jambes s’étaient figées sans qu’il ne se rende compte. L’enseigne était abîmé mais on pouvait encore distinguer ce symbole de liberté du monde d’avant. Luis n’avait pas eu la plus belle des vies mais tous les soirs, sans exception, il se rendait dans ce bar et dansait jusqu’à ce que ses jambes ne le porte plus. La pression de la journée se noyait dans un tourbillon de basses cadencées et de mouvements désarticulés. Il bougeait ses membres sans retenue au rythme d’une musique incroyablement bestiale. L’allure n’était pas harmonieuse mais il ne pouvait se passer de cet instant de liberté quotidienne où il n’était ni jugé ni observé. Il ferma les yeux.`}</p>
    <p>{`Il y est. Entouré d’une masse anonyme rassemblée par l’objectif commun de se défouler et de relâcher de la tension. Ivre, il sourit sans se soucier du monde qui l’entoure. Les limites ne sont que physiques. La musique bat son rythme, les lumières sont psychédéliques, les gestes frénétiques. Il n’y a rien à comprendre. L’euphorie est partagée. Une union invisible et coordonnée se crée parmi la foule enivrée. Le son est bon et l’atmosphère électrique. Le monde rit, ivre d’allégresse, il se révolte contre la pression imposée par le système. Luis est le maillon insignifiant d’une puissance libératrice sans limite.`}</p>
    <p>{`« Mais avancez, bon sang ! » L’officier impérial criait sur Luis, seul et immobile dans la rue, depuis quelques minutes maintenant et il aura fallu un coup de matraque dans les jambes pour le faire réagir. Il reprit la marche forcée. Autour de lui, tous les passants le regardaient avec pitié. Gauche. Droite. Gauche. Droite. Toujours ce mouvement machinal. Entouré d’automates, il était là le maillon d’une chaîne oppressante et étouffante. Les gens se complaisaient dans cet univers imposé mais maintenant il savait, il y avait un monde meilleur, le monde d’après.`}</p>
    <p>{`Luis s’arrêta. Quelques pas à gauche. Quelques pas à droite. Deux pas en arrière. Quatre en avant. La révolte était lancée.`}</p>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      